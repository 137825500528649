<style>
#error-container {
  height: 100vh;
}
</style>
<template>
  <v-container id="error-container" class="d-flex flex-column align-center justify-center">
    <v-card>
      <v-card-title>
        <h1 class="text-h2"><v-icon x-large left>fas fa-biohazard</v-icon> Oops</h1>
      </v-card-title>
      <v-card-text>
        <p class="text-h4">{{ errorMessage }}</p>
        <p class="text-h4">
          <v-btn color="primary" :to="{ name: 'Dashboard' }">Go To Dashboard</v-btn>
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: String,
      required: false
    }
  },
  computed: {
    errorMessage () {
      return this.error || 'There was an unexpected error trying to navigate to that page.'
    }
  }
}
</script>
